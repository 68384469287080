import React from 'react'

const Menu = () => {
  return (
    <div>
        <div className='menu'>
            <div className='item'>
                <h1>Pizzas</h1>
                <h3>Cheese $8</h3>
                <div>
                    <h3>Margarita Pizza $12</h3>
                    <p>San Marzano Sauce, Aged Parmesan, Olive Oil, Fresh Mozzarella, Fresh Basil</p>
                </div>
                <div>
                    <h3>Little Italy $12</h3>
                    <p>Italian Sausage, Sauteed Peppers, Onion, Mozzarella</p>
                </div>
                <div>
                    <h3>Pepperoni $10</h3>
                    <p>Enough Said</p>
                </div>
                <div>
                    <h3>Carnivore's Dream $13</h3>
                    <p>Italian sausage, Burnt Ends Sausage Infused With Brisket Pieces</p>
                </div>
                <div>
                    <h3>Supreme Classic $12</h3>
                    <p>Pepperoni, Italian Sausage, Peppers, Onion</p>
                </div>
                <div>
                    <h3>Spicy Meatball</h3>
                    <p>Italian Sausage, Fresh Jalapeno, Cream Cheese</p>
                </div>
            </div>
            <div className='hours'>
                <div className='overlay'>
                    <div>
                        <h1>Hours Of Operation</h1>
                        <h2>11 a.m. - 8 p.m.</h2>
                        <h2>Tuesday, Wednesday<br />Friday, Saturday</h2>
                    </div>
                    <div>
                        <a href="mailto:fahrenheit851pizza@gmail.com">Fahrenheit851pizza@gmail.com</a>
                        <h3>Located at</h3>
                        <h3>301 W. Platte Ave.</h3>
                        <h3>Fort Morgan, CO</h3>
                        <h3>Phone</h3>
                        <a href="tel:9704736254">(970)-473-6254</a>
                    </div>
                </div>
            </div>
            <div className='item'>
                <h1>Sandwiches From the Oven</h1>
                <div>
                    <h3>Southwest Cowboy $14</h3>
                    <p>Fresh Baked Bun: BBQ Sauce, Birria Roast, Toasted Mozzarella, Fresh Jalapeno, Cream Cheese</p>
                </div>
                <div>
                    <h3>Toasty Italian $12</h3>
                    <p>Fresh Baked Bun: Loaded With Italian Sausage, Peppers, Onions, and Melted Mozzarella</p>
                </div>
                <div>
                    <h3>Add Ons</h3>
                    <p>Meat - $2 each</p>
                    <p>Veggies - $1 each</p>
                    <p>Cheese - $2 each</p>
                </div>
                <div>
                    <h3>Soda - $2</h3>
                </div>
                <div>
                    <h3>Desserts $4</h3>
                    <p>Bacon Wrapped Cinnamon Roll with Caramel</p>
                </div>
            </div>
        </div>
        <div className='container'>
            <h2>Add Any Of These</h2>
            <div className='ingredients'>
                <div>
                    <h4>Meats</h4>
                    <ul>
                        <li>Pepperoni</li>
                        <li>Italian Sausage</li>
                        <li>Sausage Link Burnt Ends</li>
                        <li>Birria Roast</li>
                        <li>Bacon</li>
                    </ul>
                </div>
                <div>
                    <h4>Veggies</h4>
                    <ul>
                        <li>Pineapple</li>
                        <li>Onion</li>
                        <li>Peppers</li>
                        <li>Jalapenos</li>
                        <li>Mushrooms</li>
                        <li>Basil</li>
                    </ul>
                </div>
                <div>
                    <h4>Other</h4>
                    <ul>
                        <li>Cream Cheese</li>
                        <li>Fresh Mozzarella</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Menu